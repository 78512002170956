import { createClient, Session } from "@supabase/supabase-js";

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_KEY;

if (!supabaseUrl || !supabaseAnonKey)
  alert(
    "configuration error, missing REACT_APP_SUPABASE_URL or REACT_APP_SUPABASE_KEY"
  );
export const supabase = createClient(supabaseUrl, supabaseAnonKey);

export type SupabaseSession = Session;
