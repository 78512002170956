import { CombinedError } from "@urql/core";
import _ from "lodash";
import { useCallback, useState } from "react";
import { UseFormSetError } from "react-hook-form";

export const errorMessages = (error: CombinedError) => {
  return error.message;
};

// export const handleErrorsInForm = <T>(
//   error: CombinedError,
//   setError: UseFormSetError<T>
// ) => {
//   if (typeof error === "undefined")
//     throw Error("Type error: passing undefined to handleErrorInForm");

//   const setError1 = setError as (name: any, opts: any) => void;
//   if (error.graphQLErrors) {
//     error.graphQLErrors.forEach((e) => {
//       const pathName = e.path ? e.path?.slice(1).join(".") : "";
//       setError1(pathName, { type: "manual", message: e.message });
//     });
//   }
// };

// Because React boundaries cannot catch error thrown in asynsc code
// but just within render functions or hook mechanics,
// you can use this hook to pass Your error to react boundary.
// Use like this:
// const handleError = useAsyncError();
// (.....).catch(e => handleError(e));

export const useAsyncError = () => {
  const [, setError] = useState();
  return useCallback(
    (e) => {
      console.error("in useAsyncError block:", e);
      setError(() => {
        throw e;
      });
    },
    [setError]
  );
};

export const logError = (e: Error): void => {
  console.error("error", e);
};

export const countries = {
  at: "Austria",
  be: "Belgium",
  bg: "Bulgaria",
  cy: "Cyprus",
  cz: "Czech Republic",
  de: "Germany",
  dk: "Denmark",
  ee: "Estonia",
  es: "Spain",
  fi: "Finland",
  fr: "France",
  gr: "Greece",
  hu: "Hungary",
  hr: "Croatia",
  ie: "Ireland",
  it: "Italy",
  lt: "Lithuania",
  lu: "Luxembourg",
  lv: "Latvia",
  mt: "Malta",
  nl: "Netherlands",
  pl: "Poland",
  pt: "Portugal",
  ro: "Romania",
  se: "Sweden",
  si: "Slovenia",
  sk: "Slovakia",
  no: "Norway",
};

export const supportedLocale = [
  "ar",
  "bg",
  "ca",
  "ce",
  "cs",
  "da",
  "de",
  "de@informal",
  "el",
  "en",
  "en_GB",
  "es",
  "et",
  "fi",
  "fr",
  "fr_CA",
  "ga",
  "he",
  "hi",
  "hr",
  "hu",
  "it",
  "lb",
  "lt",
  "lv",
  "mt",
  "nl",
  "pl",
  "pt",
  "ro",
  "ru",
  "sk",
  "sl",
  "sr",
  "sv",
  "no",
];

export function localeToCountry(locale: string): string {
  const parts = locale.split("_");
  if (parts.length > 1) {
    return parts[1].toUpperCase();
  } else if (parts.length === 1) {
    const c = parts[0].toUpperCase();
    if (c === "BR") return "BG"; // handle common fake locale: en (should be en_US or en_GB)
    if (c === "AR") return "PS"; // no particular country speaks arabic, selecting Palestine
    if (c === "EN") return "GB"; // handle common fake locale: en (should be en_US or en_GB)
    return c;
  }
  return;
}

export function cleanse(obj: any | null): any {
  if (obj === null) return obj;
  Object.keys(obj).forEach(function (key) {
    // Get this value and its type
    var value = obj[key];
    var type = typeof value;
    if (type === "object") {
      // in retarded JS world, null is an object;
      // (In API, passing null as argument could have perfect meaning so we do not remove key: null)
      if (value === null) return null;
      // cleanse the object itself
      cleanse(value);

      // ...and now see if it contains any keys, remove if {}
      if (!Object.keys(value).length) {
        delete obj[key];
      }
    } else if (type === "undefined" || (type === "string" && value === "")) {
      // Undefined, remove it
      delete obj[key];
    }
  });
  return obj;
}

// generated using Python with babel package:
// {lc: babel.Locale(lc).display_name for lc in supportedLocale}

export const localeName: Record<string, string> = {
  cs: "čeština (CS)",
  sv: "svenska (SV)",
  lv: "latviešu (LV)",
  hr: "hrvatski (HR)",
  es: "español (ES)",
  en_GB: "British English (EN_GB)",
  it: "italiano (IT)",
  ar: "العربية (AR)",
  hu: "magyar (HU)",
  el: "Ελληνικά (EL)",
  fr_CA: "français canadien (FR_CA)",
  fr_be: "fraçais belge (FR_BE)",
  ga: "Gaeilge (GA)",
  sl: "slovenščina (SL)",
  fi: "suomi (FI)",
  da: "dansk (DA)",
  sk: "slovenčina (SK)",
  mt: "Malti (MT)",
  ca: "català (CA)",
  fr: "français (FR)",
  lb: "letzebuergesch (LB)",
  lt: "lietuvių (LT)",
  de: "Deutsch (DE)",
  "de@informal": "Deutsch informell (DE@INFORMAL)",
  ru: "русский (RU)",
  bg: "български (BG)",
  en: "English (EN)",
  et: "eesti (ET)",
  ro: "română (RO)",
  pl: "polski (PL)",
  hi: "हिन्दी (HI)",
  pt: "português (PT)",
  he: "עברית   (HE)",
  sr: "српски (SR)",
  ce: "нохчийн (CE)",
  nl: "Nederlands (NL)",
  nb_NO: "norsk bokmål (NB_NO)",
};

export const urlPattern =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const formatNumber = new Intl.NumberFormat("en-UK").format;

type Lang = {
  lang: string;
};

export const languageObject = (langs) => {
  return (
    _.pick(
      localeName,
      Object.values(langs).map((language: Lang) =>
        language?.lang?.toLowerCase()
      )
    ) || {}
  );
};

type Area = {
  area: string;
};

export const areaObject = (areas) => {
  return _.pick(
    countries,
    Object.values(areas).map((area: Area) => area?.area?.toLowerCase())
  );
};

// to check if there are pictures or comments in the campaign
const component2tab = (component) => {
  const maps = { "portal/DispatchComment": "comments", "field/Camera": "pics" };
  return maps[component];
};
export const getFeatures = (campaign) => {
  return campaign && campaign.config.portal
    ? campaign.config.portal
        .map((d) => component2tab(d.component || d))
        .filter((d) => d !== undefined)
    : [];
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;
