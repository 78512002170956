import { Avatar, Box, Container, Grid, Paper } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { FunctionComponent, ReactNode, Suspense } from "react";
import AppErrorBar from "src/components/AppErrorBar";
import { ProcaLogo } from "../lib/icons";
import { iconStyles } from "../lib/styles";

// import { ucs2 } from "punycode";

type Props = {
  children?: ReactNode;
  classes: any;
  footer?: ReactNode;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false; // todo marcin: how to inherit it?
};

const useStyles = makeStyles((theme) => ({
  "@global": {
    html: {
      [theme.breakpoints.down('lg')]: {
        animation: "none",
      },
    },
  },
  ".dialog": {
    margin: theme.spacing(4, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
    },
  },
  main: {
    minWidth: theme.breakpoints.values.sm,
    backgroundColor: theme.palette.background.default,
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0),
      padding: theme.spacing(0),
    },
  },
}));

const StyledEntryFrame: FunctionComponent<Props> = (props) => {
  const { children } = props;
  const _classes = useStyles();

  return (
    <Container component="main" maxWidth={props.maxWidth || "sm"}>
      <Paper className={_classes.main} elevation={3}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item>
            <Box className="dialog">
              <a href="/">
                <Avatar className={_classes["largeIcon"]}>
                  <ProcaLogo />
                </Avatar>
              </a>
            </Box>
          </Grid>
          <Suspense fallback={<p>...</p>}>
            <Grid item>{children}</Grid>
          </Suspense>
          {props.footer && (
            <Grid item>
              <Box my={4}>{props.footer}</Box>
            </Grid>
          )}
        </Grid>
      </Paper>
      <AppErrorBar />
    </Container>
  );
};
const EntryFrame = withStyles(iconStyles)(StyledEntryFrame);
export default EntryFrame;
