import { Theme } from "@mui/material/styles";

import createStyles from '@mui/styles/createStyles';

/*
  The MUI styles API is not intuitive; see https://material-ui.com/styles/api/
  for details or read on for a summary:

  # TL;DR: use withStyles() only
  wrap definitions in createStyles() to stop TS bugs.
  the hook api for styles seems verbose for no reason.

 # utility
 createStyles - identity function fixing types. Call over the object with style definition.

 # hook api:
 makeStyles() - hook API maker, use: 
    const useStyles = makeStyles( theme => createStyles(...))
    then: const classes = useStyles();

 # component api:
 withStyles - alernative to makeStyles,  run over component to inject props.classes. Seems less coding with same result

 # theme:
 withTheme() - pass theme to props

 theme will be given to a style anyway I think withStyles(theme => ... ) will receive the theme object

 # To use MUI CSS API: use classes prop:
 <Compo classes={{root: classes.foo, paper: classes.bar}} >..

 */

export const iconStyles = (theme: Theme) =>
  createStyles({
    largeIcon: {
      width: "6em",
      height: "6em",
      backgroundColor: "transparent",
      //margin: theme.spacing(4)
    },
    mediumIcon: {
      width: "3em",
      height: "3em",
      margin: theme.spacing(2),
    },
  });

export const brandStyles = (theme: Theme) =>
  createStyles({
    gradient45: {
      background: `linear-gradient(45deg, ${theme.palette.primary.main} 0%, ${theme.palette.error.main} 80%)`,
    },
  });

export const listStyles = (theme: Theme) =>
  createStyles({
    selected: {
      backgroundColor: "#f5f5f5",
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
    },
    avatarSecondary: {
      backgroundColor: theme.palette.secondary.main,
    },
    fullWidth: {
      width: "100%",
    },
    summary: {
      "&.Mui-focused": {
        backgroundColor: "inherit",
      },
    },
    nested: {
      paddingLeft: theme.spacing(6),
    },
    noIcon: {
      paddingLeft: theme.spacing(9),
    },

    iconPicture: {
      maxWidth: theme.spacing(5),
      paddingRight: theme.spacing(1),
      "& img": {
        width: "100%",
      },
    },
  });

export const paperStyles = (theme: Theme) =>
  createStyles({
    //removing spacing1, it created warnings
  });

export const withManyStyles = (...styles: any) => {
  return (theme: Theme) => {
    const outStyles = styles.map((arg: any) => {
      // Apply the "theme" object for style functions.
      if (typeof arg === "function") {
        return arg(theme);
      }
      // Objects need no change.
      return arg;
    });

    return outStyles.reduce((acc: any, val: any) => Object.assign(acc, val));
  };
};
