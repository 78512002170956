import { FunctionComponent, useEffect, ReactNode } from "react";
import { useAppState } from "src/state";

type Props = {
  children?: ReactNode;
};

const IdentityProvider: FunctionComponent<Props> = (props) => {
  const [app, actions] = useAppState();

  useEffect(() => {
    actions.ensureSignIn(app.sso.session);
  }, [actions]);

  if (!app.sso.session?.user) {
    if (window.location.hash) {
      // Fragment exists
      const tmp = window.location.hash.substring(1).split("&");
      let key = {};
      tmp.forEach((d) => {
        const v = d.split("=");
        if (v.length === 2) {
          key[v[0]] = decodeURIComponent(v[1].replace(/\+/g, " "));
        }
      });
      if (key.error) {
        function IdentityException(key) {
          this.message = key.error_description;
          this.code = key.error_code;
          this.name = key.error;
        }
        throw new IdentityException(key);
      }
    }

    return null; // loading
  }

  return <>{props.children}</>;
};

export default IdentityProvider;
