import {
  Box,
  Dialog as MuiDialog,
  DialogContent,
  DialogTitle,
  LinearProgress,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import { ReactComponent as FixQuoLogo } from "src/images/fixthestatusquo.svg";
//import { ReactComponent as ProcaLogo } from "src/images/Proca.svg";
import { ReactComponent as ProcaLogo } from "src/images/proca-icon-darkactive.svg";

const useStyles = makeStyles((theme) => ({
  poweredby: {
    textAlign: "right",
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.secondary,
    fontSize: 13,
  },
  svg: {
    display: "inline",
    maxWidth: 160,
    verticalAlign: "middle",
  },
}));

export const ProcaTopLeft = (props) => {
  return (
    <svg height="100" width="100">
      title="homepage dashboard"
      <circle cx="0" cy="0" r="40" fill="white" />
      <circle cx="34" cy="34" r="40" fill="white" />
      <ProcaLogo x="10" y="10" width="48" height="48" title="Proca logo" />
    </svg>
  );
};

const Dialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const classes = useStyles();

  return (
    <MuiDialog
      fullScreen={fullScreen}
      open
      fullWidth
      scroll="body"
      maxWidth="sm"
      hideBackdrop
      PaperProps={{
        elevation: 3,
      }}
    >
      <DialogTitle>
        {props.title}
        {props.poweredby && (
          <Box className={classes.poweredby}>
            powered by <FixQuoLogo className={classes.svg} />
          </Box>
        )}
      </DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      {props.loading && <LinearProgress />}
    </MuiDialog>
  );
};

export default Dialog;
