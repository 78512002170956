import {
  Button,
  Card,
  CardContent,
  Grid,
  SvgIcon,
  TextField,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import EmailIcon from "@mui/icons-material/Email";
import LinkedInLogo from "@mui/icons-material/LinkedIn";
import { Alert, AlertTitle } from '@mui/material';
import { FunctionComponent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import TwitterIcon from "src/images/Twitter";
import { GoogleLogo } from "src/lib/icons";
import { supabase } from "src/lib/supabase";

type Props = {};

// True only if /welcome is intercepted
const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
}));

const SignIn: FunctionComponent<Props> = (props) => {
  const [authError, setAuthError] = useState(null);
  const [email, setEmail] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [emailCode, setEmailCode] = useState<string | null>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const classes = useStyles();
  const navigate = useNavigate();

  let returnTo = query.get("return_to") || "";

  if (returnTo && query.get("email")) {
    returnTo += `&email=${query.get("email")}`;
  }
  const redirect = `${window.location.origin}${returnTo}`;
  const options = { redirectTo: window.location.origin + returnTo};

  const handleLogin = async (provider) => {
    const { error } = await supabase.auth.signInWithOAuth({ provider: provider, options: options });
    if (error) console.error(error);
  };

  const handleMailClick = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      {authError && (
        <Alert severity="error">
          <AlertTitle>{authError.status === 429 ? "Too many requests" : "Error"}</AlertTitle>{authError.message}
        </Alert>
      )}
      <Grid container item xs={12}>
        <Button
          variant="contained"
          fullWidth
          className={classes.button}
          color="secondary"
          startIcon={
            <SvgIcon>
              <TwitterIcon />
            </SvgIcon>
          }
          onClick={() => handleLogin("twitter")}
        >
          with Twitter
        </Button>
        <Button
          variant="contained"
          color="secondary"
          fullWidth
          className={classes.button}
          startIcon={
            <SvgIcon>
              <GoogleLogo />
            </SvgIcon>
          }
          onClick={() => handleLogin("google")}
        >
          with Google
        </Button>
        <Button
          variant="contained"
          fullWidth
          className={classes.button}
          color="secondary"
          startIcon={
            <SvgIcon>
              <LinkedInLogo />
            </SvgIcon>
          }
          onClick={() => handleLogin("linkedin")}
        >
          with LinkedIn
        </Button>
        <Button
          variant="contained"
          fullWidth
          className={classes.button}
          color={showForm ? "primary" : "inherit"}
          startIcon={<EmailIcon />}
          onClick={handleMailClick}
        >
          with email
        </Button>
      </Grid>
      {showForm && (
        <form>
          <Card>
            <CardContent>

              {emailCode === null ?
                (<Grid container spacing={2} alignItems="center">
                <Grid item xs={12} sm={9}>
                  <TextField
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    size="small"
                      variant="filled"
                      disabled={disabled}

                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button
                      type="submit"
                      name="email"
                      color="primary"
                      variant="contained"
                      disabled={disabled}
                      onClick={async (e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          setDisabled(true);
                            const { error, data } = await supabase.auth.signInWithOtp({
                              email: email,
                              options: {
                               emailRedirectTo: redirect
                              }
                            })
                            if (data || error) setDisabled(false);
                            error ? setAuthError(error) : setEmailCode("");
                            }
                      }
                    >
                    Send
                  </Button>
                </Grid>
              </Grid>)
              :
             (<Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={9}>
                <TextField
                  value={emailCode}
                  label="Login code"
                  size="small"
                      variant="filled"
                      onChange={(e) => setEmailCode(e.target.value.trim())}
                      helperText="Enter the code received by email"
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                    <Button name="code"
                      color="primary"
                      variant="contained"
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        const { error } = await supabase.auth.verifyOtp({ email: email, token: emailCode, type: "email" });
                        error ? setAuthError(error) : navigate(returnTo);
                    }}>
                  Login
                </Button>
              </Grid>
            </Grid>)
            }
            </CardContent>
          </Card>
        </form>
      )}
    </>
  );
};

export default SignIn;