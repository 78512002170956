import Avatar from "@mui/material/Avatar";
import SvgIcon from "@mui/material/SvgIcon";
import AssistantIcon from "@mui/icons-material/Assistant";
import React from "react";
import { ReactComponent as CoalitionImage } from "../images/icons/coalition.svg";
import { ReactComponent as RocketLaunchImage } from "../images/icons/rocket_launch.svg";
import procaLogoLightIdle512Image from "../images/proca-icon-dark.png";
import procaLogoDark512Image from "../images/proca-icon-darkactive-512.png";
export const MainIcon = () => <AssistantIcon style={{ marginRight: 0 }} />;
export {
  default as Stats,
  default as StatsIcon,
} from "@mui/icons-material/BarChart";
export { default as HelpIcon } from "@mui/icons-material/Help";
export { default as SupporterIcon } from "@mui/icons-material/Person";
export {
  default as EditDetails,
  default as Settings,
  default as SettingsIcon,
} from "@mui/icons-material/Settings";
export { default as TextIcon } from "@mui/icons-material/TextFields";
export { default as WidgetIcon } from "@mui/icons-material/Widgets";
export { default as CampaignIcon } from "../images/CampaignIcon";
export { ReactComponent as GoogleLogo } from "../images/google-icon.svg";
export { ReactComponent as ProcaLogoDark } from "../images/proca-icon-darkactive.svg";
export { ReactComponent as ProcaLogoLightIdle } from "../images/proca-icon-lightidle.svg";
export { ReactComponent as ProcaLogo } from "../images/proca-logo.svg";
export { CoalitionIcon as Coalition };

export const RocketLaunch = (props: any) => (
  <SvgIcon>
    <RocketLaunchImage />
  </SvgIcon>
);
export const CoalitionIcon = (props: any) => (
  <SvgIcon>
    <CoalitionImage />
  </SvgIcon>
);

/*
 * XXX why do I use PNG files here.
 * If I use SVG's with gradient (all our logos have gradient) there is a clash of
 * gradient ID when there are more then 1 copies of the icon.
 * And it seems that MUI-treasury sidebar uses 2 copies (i guess one for desktop one for mobile)
 * and in result the gradient is broken. Unsure what would be the best fix.
 */
export const ProcaLogoDarkAvatar = (props: any) => (
  <Avatar {...props} alt="Proca" src={procaLogoDark512Image} />
);

export const ProcaLogoLightIdleAvatar = (props: any) => (
  <Avatar {...props} alt="Proca" src={procaLogoLightIdle512Image} />
);
